import React from 'react'

import Layout from '../../layouts'
import MetaHeader from '../../components/MetaHeader'
import {
  CustomButton,
  Form,
  Input,
  Label,
  Section,
  Text,
  Title,
} from '../../components/future-capital-executive-program'
import { colors } from '../../components/theme'

const FutureCapitalExecutiveProgram = () => {
  return (
    <Layout>
      <MetaHeader
        title="Future Capital Overview"
        description="Future Capital is the first executive education program of its kind, focused on tooling female corporate leaders with investment education."
      />
      <Section
        width={[1, 1, 3 / 4, 900]}
        px={[3, 4, 0]}
        paddingTop={[3, 5]}
        my={0}
        mx="auto"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between">
        <Section width={[1]} mb={[3, 4]}>
          <Title
            color={colors.secondaryText}
            fontSize={[60]}
            fontFamily="futura-pt"
            fontWeight={500}
            textAlign="center"
            mt={[4]}
            mb={0}>
            Future Capital Executive Program
          </Title>
          <Text color={colors.primary} textAlign="center" fontSize={31} mt={3}>
            First intake Spring 2020
          </Text>
        </Section>
        <Section
          width={[1, 1, 9 / 16]}
          mx="auto"
          bg="#2C6A77"
          px={[4, 5]}
          py={5}>
          <Form width={1} flexDirection="column">
            <input
              type="hidden"
              name="form-name"
              value="FutureCapitalExecProgram"
            />
            <input type="hidden" name="bot-field" />
            <Label color={colors.white} htmlFor="fullname">
              Full Name
            </Label>
            <Input type="text" name="fullname" required mb={4} />
            <Label color={colors.white} htmlFor="fullname">
              Email
            </Label>
            <Input type="email" name="email" required mb={4} />
            <Label color={colors.white} htmlFor="fullname">
              Company
            </Label>
            <Input type="text" name="company" required mb={4} />
            <Label color={colors.white} htmlFor="fullname">
              City
            </Label>
            <Input type="text" name="city" required mb={4} />
            <Label color={colors.white} htmlFor="fullname">
              Country
            </Label>
            <Input type="text" name="country" required mb={4} />
            <CustomButton type="submit">GET UPDATES</CustomButton>
          </Form>
        </Section>
      </Section>
    </Layout>
  )
}

export default FutureCapitalExecutiveProgram
